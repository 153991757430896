export const getSixtyEightData = () => {
  return [
    {
      carouselImages: [
        "1968Room/Carousel2/1968-1-2000x1000.jpg",
        "1968Room/Carousel2/1968-2-2000x1000.jpg",
        "1968Room/Carousel2/1968-3-2000x1000.jpg",
        "1968Room/Carousel2/1968-4-2000x1000.jpg",
        "1968Room/Carousel2/1968-5-2000x1000.jpg",
        "1968Room/Carousel2/1968-6-2000x1000.jpg",
        "1968Room/Carousel2/1968-7-2000x1000.jpg",
        "1968Room/Carousel2/1968-8-2000x1000.jpg",
        "1968Room/Carousel2/1968-9-2000x1000.jpg",
        "1968Room/Carousel2/1-GroundsMap.png",
      ],
      title: "1968 Room",
      // soldOut: true,
      // subTitle: '',
      bottomLinks: [
        {
          title: "gallery",
          link: "modal",
          icon: "gallery",
        },
        // {
        //   title: "watch video",
        //   link: "video",
        //   icon: "video",
        //   videoActive: true,
        //   modalVideo: false,
        //   // videoID: '415272182'
        //   videoID: "709646331",
        // },
        // {
        //     title: 'map view',
        //     link: '',
        //     icon: 'map'
        // },
        // {
        //     title: 'view from seat',
        //     link: '',
        //     icon: 'seat'
        // },
        {
          title: "buy now",
          link: "#",
          // offerId: "fv-offer--20230414-HAV-607",
          icon: "buy",
        },
        // {
        //   title: "appointment",
        //   link: "appointment",
        //   icon: "appointment",
        // },
      ],
    },
  ];
};
