export const getSixtyEightDateCardData = () => {
  return [
    {
      date: "Mon, Aug 28, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,428</span>`,
      title: `Session 1 <br> Men's and Women's Round 1`,
      subtitle: ``,
      // button: `SOLD OUT`,
      button: `View Packages`,
      modalDay: "Monday",
      modalDate: "28",
      modalMonth: "August",
      info: {
        day: "1",
        session: "1",
        points: ["Men's 1st Round", "Women's 1st Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-first-round-mini-plans-3xgfddb-68b38e1",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140291",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      // category: "NOT",
      category: "AVAILABLE",
      isDisabled: false,
    },
    {
      date: "Mon, Aug 28, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,885</span>`,
      title: `Session 2 <br> Men's and Women's Round 1`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Monday",
      modalDate: "28",
      modalMonth: "August",
      info: {
        day: "1",
        session: "2",
        points: ["Men's 1st Round", "Women's 1st Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-first-round-mini-plans-3xgfddb-68b38e1",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140292",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Tue, Aug 29, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,542</span>`,
      title: `Session 3 <br> Men's and Women's Round 1`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Tuesday",
      modalDate: "29",
      modalMonth: "August",
      info: {
        day: "2",
        session: "3",
        points: ["Men's 1st Round", "Women's 1st Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-first-round-mini-plans-3xgfddb-68b38e1",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140293",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Tue, Aug 29, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,942</span>`,
      title: `Session 4 <br> Men's and Women's Round 1`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Tuesday",
      modalDate: "29",
      modalMonth: "August",
      info: {
        day: "2",
        session: "4",
        points: ["Men's 1st Round", "Women's 1st Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-first-round-mini-plans-3xgfddb-68b38e1",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140294",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Wed, Aug 30, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,657</span>`,
      title: `Session 5 <br> Men's and Women's Round 2`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Wednesday",
      modalDate: "30",
      modalMonth: "August",
      info: {
        day: "3",
        session: "5",
        points: ["Men's 2nd Round", "Women's 2nd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-second-round-mini-plans-j5bhou-bdc8d16",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140295",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Wed, Aug 30, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,942</span>`,
      title: `Session 6 <br> Men's and Women's Round 2`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Wednesday",
      modalDate: "30",
      modalMonth: "August",
      info: {
        day: "3",
        session: "6",
        points: ["Men's 2nd Round", "Women's 2nd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-second-round-mini-plans-j5bhou-bdc8d16",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140296",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Thu, Aug 31, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,657</span>`,
      title: `Session 7 <br> Men's and Women's Round 2`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Thursday",
      modalDate: "31",
      modalMonth: "August",
      info: {
        day: "4",
        session: "7",
        points: ["Men's 2nd Round", "Women's 2nd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-second-round-mini-plans-j5bhou-bdc8d16",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140297",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Thu, Aug 31, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$2,285</span>`,
      title: `Session 8 <br> Men's and Women's Round 2`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Thursday",
      modalDate: "31",
      modalMonth: "August",
      info: {
        day: "4",
        session: "8",
        points: ["Men's 2nd Round", "Women's 2nd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-second-round-mini-plans-j5bhou-bdc8d16",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140298",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Fri, Sep 1, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$1,828</span>`,
      title: `Session 9 <br> Men's and Women's Round 3`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Friday",
      modalDate: "1",
      modalMonth: "September",
      info: {
        day: "5",
        session: "9",
        points: ["Men's 3rd Round", "Women's 3rd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId:
            "hospitality-friday-labor-day-weekend-mini-plan-bcicp4d-bf5fa38",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-2140299",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Fri, Sep 1, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,828</span>`,
      title: `Session 10 <br> Men's and Women's Round 3`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Friday",
      modalDate: "1",
      modalMonth: "September",
      info: {
        day: "5",
        session: "10",
        points: ["Men's 3rd Round", "Women's 3rd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId:
            "hospitality-friday-labor-day-weekend-mini-plan-bcicp4d-bf5fa38",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402910",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sat, Sep 2, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$2,056</span>`,
      title: `Session 11 <br> Men's and Women's Round 3`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Saturday",
      modalDate: "2",
      modalMonth: "September",
      info: {
        day: "6",
        session: "11",
        points: ["Men's 3rd Round", "Women's 3rd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId:
            "hospitality-saturday-labor-day-weekend-mini-plan-4cyctf9-97ae29d",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402911",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sat, Sep 2, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,771</span>`,
      title: `Session 12 <br> Men's and Women's Round 3`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Saturday",
      modalDate: "2",
      modalMonth: "September",
      info: {
        day: "6",
        session: "12",
        points: ["Men's 3rd Round", "Women's 3rd Round"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId:
            "hospitality-saturday-labor-day-weekend-mini-plan-4cyctf9-97ae29d",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402912",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sun, Sep 3, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$2,056</span>`,
      title: `Session 13 <br> Men's and Women's Round of 16`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Sunday",
      modalDate: "3",
      modalMonth: "September",
      info: {
        day: "7",
        session: "13",
        points: ["Men's Round of 16", "Women's Round of 16"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId:
            "hospitality-sunday-labor-day-weekend-mini-plan-hxjurcp-9a64c89",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402913",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sun, Sep 3, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,771</span>`,
      title: `Session 14 <br> Men's and Women's Round of 16`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Sunday",
      modalDate: "3",
      modalMonth: "September",
      info: {
        day: "7",
        session: "14",
        points: ["Men's Round of 16", "Women's Round of 16"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId:
            "hospitality-sunday-labor-day-weekend-mini-plan-hxjurcp-9a64c89",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402914",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Mon, Sep 4, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$2,056</span>`,
      title: `Session 15 <br> Men's and Women's Round of 16`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Monday",
      modalDate: "4",
      modalMonth: "September",
      info: {
        day: "8",
        session: "15",
        points: ["Men's Round of 16", "Women's Round of 16"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId:
            "hospitality-monday-labor-day-weekend-mini-plan-4aj7ehp-1e00c7b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402915",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Mon, Sep 4, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$1,714</span>`,
      title: `Session 16 <br> Men's and Women's Round of 16`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Monday",
      modalDate: "4",
      modalMonth: "September",
      info: {
        day: "8",
        session: "16",
        points: ["Men's Round of 16", "Women's Round of 16"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId:
            "hospitality-monday-labor-day-weekend-mini-plan-4aj7ehp-1e00c7b",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402916",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Tue, Sep 5, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$2,742</span>`,
      title: `Session 17 <br> Men's and Women's Quarterfinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Tuesday",
      modalDate: "5",
      modalMonth: "September",
      info: {
        day: "9",
        session: "17",
        points: ["Men's Quarterfinals", "Women's Singles Quarterfinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-quarter-finals-mini-plan-24wkq9p-0eef758",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402917",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Tue, Sep 5, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$4,112</span>`,
      title: `Session 18 <br> Men's and Women's Quarterfinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Tuesday",
      modalDate: "5",
      modalMonth: "September",
      info: {
        day: "9",
        session: "18",
        points: ["Men's Quarterfinals", "Women's Quarterfinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-quarter-finals-mini-plan-24wkq9p-0eef758",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402918",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Wed, Sep 6, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$2,799</span>`,
      title: `Session 19 <br> Men's and Women's Quarterfinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Wednesday",
      modalDate: "6",
      modalMonth: "September",
      info: {
        day: "10",
        session: "19",
        points: ["Men's Quarterfinals", "Women's Quarterfinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-quarter-finals-mini-plan-24wkq9p-0eef758",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402919",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Wed, Sep 6, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$4,683</span>`,
      title: `Session 20 <br> Men's and Women's Quarterfinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Wednesday",
      modalDate: "6",
      modalMonth: "September",
      info: {
        day: "10",
        session: "20",
        points: ["Men's Quarterfinals", "Women's Quarterfinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          offerId:
            "2022-us-open-premium-hospitality-the-1968-room-multi-session-packages-fa64f4b",
          subOfferId: "hospitality-quarter-finals-mini-plan-24wkq9p-0eef758",
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402920",
          // subOfferId: "session-1-arthur-ashe-stadium-suhhsnh-eaff0e3",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Thu, Sep 7, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$4,683</span>`,
      title: `Session 21 <br> Women's Semifinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Thursday",
      modalDate: "7",
      modalMonth: "September",
      info: {
        day: "11",
        session: "21",
        points: ["Women's Semifinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          // offerId: '-2021-us-open-premium-hospitality-the-1968-room-individual-sessions--b594b74',
          // subOfferId: '2021-deposit-for-hospitality-czpp7xt-9642f92'
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402921",
          // subOfferId: "session-1-arthur-ashe-stadium-bxytkc5-2c2421a",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Fri, Sep 8, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$3,427</span>`,
      title: `Session 22 <br> Men's Semifinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Friday",
      modalDate: "8",
      modalMonth: "September",
      info: {
        day: "12",
        session: "22",
        points: ["Men's Semifinals"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          // offerId: '-2021-us-open-premium-hospitality-the-1968-room-individual-sessions--b594b74',
          // subOfferId: '2021-deposit-for-hospitality-czpp7xt-9642f92'
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402922",
          // subOfferId: "session-1-arthur-ashe-stadium-bxytkc5-2c2421a",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Fri, Sep 8, 7:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_night.jpg"),
      price: `Price from <span style="color: #418fde">$5,255</span>`,
      title: `Session 23 <br> Men's Semifinals`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Friday",
      modalDate: "8",
      modalMonth: "September",
      info: {
        day: "12",
        session: "23",
        points: [
          // "Men's Doubles, Final or Mixed Doubles, Final",
          "Men's Semifinals",
        ],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          // offerId: '-2021-us-open-premium-hospitality-the-1968-room-individual-sessions--b594b74',
          // subOfferId: '2021-deposit-for-hospitality-czpp7xt-9642f92'
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402923",
          // subOfferId: "session-1-arthur-ashe-stadium-bxytkc5-2c2421a",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sat, Sep 9, 12:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$4,569</span>`,
      title: `Session 24 <br> Women's Final`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Saturday",
      modalDate: "9",
      modalMonth: "September",
      info: {
        day: "13",
        session: "24",
        points: ["Women's Final"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          // offerId: '-2021-us-open-premium-hospitality-the-1968-room-individual-sessions--b594b74',
          // subOfferId: '2021-deposit-for-hospitality-czpp7xt-9642f92'
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402924",
          // subOfferId: "session-1-arthur-ashe-stadium-bxytkc5-2c2421a",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
    {
      date: "Sun, Sep 10, 1:00 PM",
      bgImage: require("@/assets/images/products/date-card_available_day.jpg"),
      price: `Price from <span style="color: #418fde">$7,882</span>`,
      title: `Session 25 <br> Men's Final`,
      subtitle: ``,
      button: `SOLD OUT`,
      // button: `View Packages`,
      modalDay: "Sunday",
      modalDate: "10",
      modalMonth: "September",
      info: {
        day: "14",
        session: "25",
        points: ["Men's, Final"],
      },
      sessions: [
        {
          title: "Multi-Session Packages",
          button: "Sold Out",
          disabled: true,
          // offerId: '-2021-us-open-premium-hospitality-the-1968-room-individual-sessions--b594b74',
          // subOfferId: '2021-deposit-for-hospitality-czpp7xt-9642f92'
        },
        {
          title: "Individual Session Packages",
          button: "Buy",
          disabled: false,
          offerId: "1968room520230530-21402925",
          // subOfferId: "session-1-arthur-ashe-stadium-bxytkc5-2c2421a",
        },
      ],
      category: "NOT",
      // category: "AVAILABLE",
      isDisabled: true,
    },
  ];
};
